import prodManifest from "../../manifest.prod.json";
import stagingManifest from "../../manifest.staging.json";

export const getManifest = (environment: "production" | "staging" | string) => {
  switch (environment) {
    case "production":
      return prodManifest;
    default:
      return stagingManifest;
  }
};
