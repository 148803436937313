// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["__sentryRewritesTunnelPath__"] = "/telemetry";globalThis["SENTRY_RELEASE"] = {"id":"next@247+46d0e6d"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import {
  BrowserClient,
  defaultStackParser,
  feedbackAsyncIntegration,
  getCurrentScope,
  makeFetchTransport,
} from "@sentry/nextjs";
import { getManifest } from "@tweetdm/core";
import { env } from "@tweetdm/env/client";

const { build, commit } = getManifest(env.NEXT_PUBLIC_STAGE);

const client = new BrowserClient({
  debug: false,
  dsn: "https://6155bd75ea233776aac900ab1423f827@o4507528156938240.ingest.us.sentry.io/4507528158707712",
  integrations: [
    feedbackAsyncIntegration({
      colorScheme: "system",
      isEmailRequired: true,
      autoInject: false,
    }),
  ],
  sampleRate: 0.1,
  tracesSampleRate: 0.25,
  enableTracing: false,
  replaysOnErrorSampleRate: 0,
  replaysSessionSampleRate: 0,
  environment: env.NEXT_PUBLIC_STAGE,
  release: `next@${build}+${commit}`,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
});

getCurrentScope().setClient(client);

client.init();
